import { inject }                                     from 'aurelia-framework';
import { ApprovedWorkingFormulasRepository }          from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/services/repository';
import { FormulationStudiesRepository }               from 'modules/bituminous-mixtures/mixtures/formulation-studies/services/repository';
import { ManufacturingPlantTranspositionsRepository } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/services/repository';
import { ApprovedWorkingFormula }                     from 'modules/bituminous-mixtures/models/approved-working-formula';
import { AdditionalInformationsRepository }           from 'modules/management/additional-information/additional-informations/services/repository';

@inject(ApprovedWorkingFormulasRepository, AdditionalInformationsRepository, FormulationStudiesRepository, ManufacturingPlantTranspositionsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param ApprovedWorkingFormulasRepository
     * @param additionalInformationRepository
     * @param formulationStudiesRepository
     * @param manufacturingPlantTranspositionsRepository
     */
    constructor(ApprovedWorkingFormulasRepository, additionalInformationRepository, formulationStudiesRepository, manufacturingPlantTranspositionsRepository) {
        this.repository                                 = ApprovedWorkingFormulasRepository;
        this.additionalInformationRepository            = additionalInformationRepository;
        this.formulationStudiesRepository               = formulationStudiesRepository;
        this.manufacturingPlantTranspositionsRepository = manufacturingPlantTranspositionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ApprovedWorkingFormula}
     */
    model() {
        let model = new ApprovedWorkingFormula();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema for lot info
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    lotInfoSchema(viewModel, readonly = false) {
        this.concession_name = {
            type:       'text',
            key:        'concession_name',
            label:      'form.field.concession',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.entity_team_name = {
            type:       'text',
            key:        'entity_team_name',
            label:      'form.field.entity-team',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.highway_name = {
            type:       'text',
            key:        'highway_name',
            label:      'form.field.highway',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.intervention_type_name = {
            type:       'text',
            key:        'intervention_type_name',
            label:      'form.field.intervention-type',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.lot_intervention_name = {
            type:       'textarea',
            key:        'lot_intervention_name',
            label:      'form.field.lot-intervention',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        return [
            [this.concession_name, this.highway_name],
            [this.intervention_type_name, this.entity_team_name],
            [this.lot_intervention_name],
        ];
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.number = {
            type:       'int',
            key:        'number',
            label:      'form.field.number',
            size:       2,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.bm_formulation_study_id = {
            type:         'select2',
            key:          'bm_formulation_study_id',
            label:        'form.field.associated-study-number',
            size:         10,
            remoteSource: () => this.formulationStudiesRepository.validatedByCurrentLotIntervention(),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue) {
                        this.formulationStudiesRepository.find(newValue).then((formulationStudy) => {
                            viewModel.model.concession_name        = formulationStudy.concession_name;
                            viewModel.model.highway_name           = formulationStudy.highway_name;
                            viewModel.model.entity_team_name       = formulationStudy.entity_team_name;
                            viewModel.model.intervention_type_name = formulationStudy.intervention_type_name;
                            viewModel.model.lot_intervention_name  = formulationStudy.lot_intervention_name;

                            viewModel.model.lot_intervention_id = formulationStudy.lot_intervention_id;
                            viewModel.model.team_id             = formulationStudy.team_id;

                            this.material_plant_transposition_ids.instance.refreshLabels();
                        });
                    } else {
                        viewModel.model.concession_name        = '';
                        viewModel.model.highway_name           = '';
                        viewModel.model.entity_team_name       = '';
                        viewModel.model.intervention_type_name = '';
                        viewModel.model.lot_intervention_name  = '';

                        viewModel.model.lot_intervention_id = null;
                        viewModel.model.team_id             = null;
                    }
                },
            ],
        };

        this.date = {
            type:       'material-ui-date-picker',
            key:        'date',
            label:      'form.field.date',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.approval_date = {
            type:       'material-ui-date-picker',
            key:        'approval_date',
            label:      'form.field.approval-date',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.material_plant_transposition_ids = {
            type:         'duallistbox',
            key:          'material_plant_transposition_ids',
            label:        'form.field.material-plant-transposition-associated-number',
            size:         12,
            remoteSource: () => viewModel.model.bm_formulation_study_id
                ? this.manufacturingPlantTranspositionsRepository.validatedByFormulationStudyId(viewModel.model.bm_formulation_study_id)
                : Promise.resolve([{}]),
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.bm_sample_id = {
            type:         'select2',
            key:          'bm_sample_id',
            label:        'form.field.bm-sample-number',
            size:         6,
            remoteSource: () => Promise.resolve([]), // TODO: AFTER LAB IS DONE
            required:     false,
            attributes:   {
                disabled: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        const schema = [
            [this.number, this.bm_formulation_study_id],
            [this.date, this.approval_date],
            [this.material_plant_transposition_ids],
            [this.bm_sample_id],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    disabled: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    disabled: true,
                },
            };

            this.validated_by = {
                type:       'text',
                key:        'validated_by',
                label:      'form.field.validated-by',
                size:       6,
                required:   false,
                hidden:     !viewModel.model.is_validated,
                attributes: {
                    disabled: true,
                },
            };

            this.validated_at = {
                type:       'text',
                key:        'validated_at',
                label:      'form.field.validated-at',
                size:       6,
                required:   false,
                hidden:     !viewModel.model.is_validated,
                attributes: {
                    disabled: true,
                },
            };

            return [...schema, [this.created_by, this.created_at], [this.validated_by, this.validated_at]];
        }

        return schema;
    }

    /**
     * Returns form schema for additional information
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    additionalInformationSchema(viewModel, readonly = false) {
        this.additional_informations = {
            type:         'duallistbox',
            key:          'additional_informations',
            label:        'form.field.additional-information',
            remoteSource: () => this.additionalInformationRepository.searchMap(),
            size:         12,
            attributes:   {
                disabled: readonly,
            },
        };

        return [
            [this.additional_informations],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('bituminous-mixtures.mixtures.approved-working-formulas.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
