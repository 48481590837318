import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ApprovedWorkingFormulasRepository extends BaseRepository {
    baseUrl = 'bituminous-mixtures/mixtures/approved-working-formulas';

    /**
     * Fetches the next (possible) number
     *
     * @returns {*}
     */
    nextNumber(lotInterventionId, teamId) {
        return this.httpClient.get(`${this.baseUrl}/${lotInterventionId}/${teamId}/next-number`);
    }

    /**
     * Search additional informations by criteria
     *
     * @param mixtureId
     * @param criteria
     * @return {*}
     */
    searchAdditionalInformations(mixtureId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${mixtureId}/additional-informations`, criteria);
    }
}
