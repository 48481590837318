import { EventAggregator }              from 'aurelia-event-aggregator';
import { inject }                       from 'aurelia-framework';
import { I18N }                         from 'aurelia-i18n';
import { FormulationStudiesRepository } from 'modules/bituminous-mixtures/mixtures/formulation-studies/services/repository';
import { LayersRepository }             from 'modules/management/bituminous-mixtures/layers/services/repository';
import { HighwaysRepository }           from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository }  from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { LotInterventionsRepository }   from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { EntitiesRepository }           from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }              from 'modules/management/concessions/teams/services/repository';
import { MaterialTypesRepository }      from 'modules/management/dynamic-fields/material-types/services/repository';
import { ConcessionsRepository }        from 'modules/management/specifications/concessions/services/repository';
import { BaseFilterFormSchema }         from 'resources/classes/base-filter-form-schema';
import { SessionStorage }               from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, LotInterventionsRepository, FormulationStudiesRepository, MaterialTypesRepository, LayersRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param concessionsRepository
     * @param entitiesRepository
     * @param teamsRepository
     * @param highwaysRepository
     * @param interventionTypesRepository
     * @param lotInterventionsRepository
     * @param formulationStudiesRepository
     * @param materialTypesRepository
     * @param layersRepository
     */
    constructor(storage, eventAggregator, i18n, concessionsRepository, entitiesRepository, teamsRepository, highwaysRepository, interventionTypesRepository, lotInterventionsRepository, formulationStudiesRepository, materialTypesRepository, layersRepository) {
        super(storage, eventAggregator, i18n);

        this.concessionsRepository        = concessionsRepository;
        this.entitiesRepository           = entitiesRepository;
        this.teamsRepository              = teamsRepository;
        this.highwaysRepository           = highwaysRepository;
        this.interventionTypesRepository  = interventionTypesRepository;
        this.lotInterventionsRepository   = lotInterventionsRepository;
        this.formulationStudiesRepository = formulationStudiesRepository;
        this.materialTypesRepository      = materialTypesRepository;
        this.layersRepository             = layersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {

        const selectedLotInformation = viewModel.appContainer.authenticatedUser.getAllSelectedLotInformation()

        this.modelDefaults = {
            ...this.modelDefaults,
            entity_name: selectedLotInformation.entity_name,
            entity_id:   selectedLotInformation.entity_id,
            team_name:   selectedLotInformation.team_name,
            team_id:     selectedLotInformation.team_id,
        };

        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {

        this.number_from = {
            type:     'int',
            key:      'number_from',
            label:    'form.field.fta-number(from)',
            size:     3,
            required: false,
        };

        this.number_to = {
            type:     'int',
            key:      'number_to',
            label:    'form.field.fta-number(to)',
            size:     3,
            required: false,
        };

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            remoteSource: () => this.concessionsRepository.all(),
            required:     false,
            observers:    [
                (newValue) => {
                    this.highway_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.concessionsRepository.highways(newValue).then((response) => {
                            this.highway_id.options = response;
                            this.highway_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        /* Selectable IDs if is not builder */
        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            size:         6,
            remoteSource: () => this.entitiesRepository.all(),
            required:     false,
            hidden:       viewModel.appContainer.authenticatedUser.isBuilder,
            attributes:   {
                disabled: false,
            },
            observers:    [
                (newValue) => {
                    this.team_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.entitiesRepository.teams(newValue).then((response) => {
                            this.team_id.options = response;
                            this.team_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.team',
            size:         6,
            remoteSource: () => (viewModel.filterModel.entity_id)
                ? this.entitiesRepository.teams(viewModel.filterModel.entity_id)
                : Promise.resolve([]),
            required:     false,
            hidden:       viewModel.appContainer.authenticatedUser.isBuilder,
            attributes:   {
                disabled: false,
            },
        };

        /* Readonly names if is builder */
        this.entity_name = {
            type:       'text',
            key:        'entity_name',
            label:      'form.field.entity',
            size:       6,
            required:   false,
            hidden:     !viewModel.appContainer.authenticatedUser.isBuilder,
            attributes: {
                readonly: true,
            },
        };

        this.team_name = {
            type:       'text',
            key:        'team_name',
            label:      'form.field.team',
            size:       6,
            required:   false,
            hidden:     !viewModel.appContainer.authenticatedUser.isBuilder,
            attributes: {
                readonly: true,
            },
        };

        /* Rest of the fields */
        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
            observers:    [
                (newValue) => {
                    this.lot_intervention_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.interventionTypesRepository.lotInterventions(newValue).then((response) => {
                            this.lot_intervention_id.options = response;
                            this.lot_intervention_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            remoteSource: () => Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.date_from = {
            type:     'material-ui-date-picker',
            key:      'date_from',
            label:    'form.field.date(from)',
            size:     3,
            required: false,
        };

        this.date_to = {
            type:     'material-ui-date-picker',
            key:      'date_to',
            label:    'form.field.date(to)',
            size:     3,
            required: false,
        };

        this.bm_formulation_study_ids = {
            type:         'multiselect-native',
            key:          'bm_formulation_study_ids',
            label:        'form.field.associated-study-number',
            size:         9,
            required:     false,
            remoteSource: () => this.formulationStudiesRepository.validatedByCurrentLotIntervention(),
        };

        this.material_types = {
            type:         'multiselect-native',
            key:          'material_types',
            label:        'form.field.material-type',
            size:         6,
            required:     false,
            remoteSource: () => this.materialTypesRepository.all(),
        };

        this.layers = {
            type:         'multiselect-native',
            key:          'layers',
            label:        'form.field.layer',
            size:         6,
            required:     false,
            remoteSource: () => this.layersRepository.all(),
        };

        this.validated = {
            type:     'boolean-options-select',
            key:      'validated',
            label:    'form.field.validated?',
            size:     3,
            required: false,
        };

        this.created_by = {
            type:     'user-entity-team-lot-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     3,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     3,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     3,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-teal filter-submit',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.number_from, this.number_to, this.concession_id],
            [this.entity_id, this.team_id],
            [this.entity_name, this.team_name],
            [this.highway_id, this.intervention_type_id],
            [this.lot_intervention_id, this.date_from, this.date_to],
            [this.bm_formulation_study_ids],
            [this.material_types, this.layers],
            [this.validated, this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
